import { Button, Card, CardContent, CardHeader, CardMedia } from '@material-ui/core';
import { useState } from 'react';
import InviteCreatorModal from './InviteCreatorModal';

type Props = {
  disabled: boolean;
  onSuccess?: () => void;
};

const InviteCreatorButton = (props: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { disabled, onSuccess } = props;

  return (
    <Card style={{ height: '100%' }}>
      {modalOpen && (
        <InviteCreatorModal
          modalOpen={modalOpen}
          onSuccess={onSuccess}
          handleClose={() => setModalOpen(false)}
        />
      )}
      <CardMedia
        image="https://images.unsplash.com/photo-1517486808906-6ca8b3f04846?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1287&q=80"
        style={{ paddingTop: '56.25%' }}
      />
      <CardHeader style={{ textAlign: 'center' }} title="Send an invite" />
      <CardContent>
        <div style={{ display: 'flex' }}>
          <Button
            style={{ width: '180px', margin: 'auto' }}
            variant="outlined"
            color="primary"
            onClick={() => setModalOpen(true)}
            disabled={disabled}
          >
            Invite a teacher
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default InviteCreatorButton;
