import {
  Grid,
  Card,
  CardMedia,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core';
import { Meeting } from 'lib/store/meeting';
import moment from 'moment';
import React from 'react';

type Props = { card: Meeting; rowClick: Function; attendees: Record<string, any>[] };

const ClassCard = (props: Props) => {
  const { card, rowClick, attendees } = props;
  const creatorInfo: Record<string, any> = Meeting.getCreatorInfoFromMeeitng(card, attendees);
  return (
    <Grid item sm={4} xs={12} style={{ padding: '5px' }} onClick={() => rowClick(card)}>
      <Card style={{ height: '100%' }}>
        <CardMedia
          image={
            card.backgroundImage
              ? card.backgroundImage
              : `https://source.unsplash.com/random/${new Date(card.createdAt).getTime() % 1000}`
          }
          style={{ paddingTop: '56.25%' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <CardHeader title={card.topic} />
          <Avatar
            src={`${creatorInfo.avatarUrl}`}
            alt={`${creatorInfo.firstName} ${creatorInfo.lastName}`}
            style={{
              margin: '20px 10px',
              cursor: 'pointer',
              display: 'inline-flex',
              width: '40px',
              height: '40px',
            }}
          />
        </div>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={9}>
              <Typography variant="caption">
                at {moment(card.scheduledAt).format('MMM DD HH:mm A')}
              </Typography>
              {card.spotsLeft !== undefined && (
                <Typography variant="subtitle1">
                  Only {card.spotsLeft} Spot{card.spotsLeft > 1 ? 's' : ''} Left!
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button variant="outlined" style={{ marginLeft: 'auto' }}>
                Join
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ClassCard;
