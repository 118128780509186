import * as React from 'react';

import CreateMeetingButton from 'components/meeting/CreateMeetingButton';
import { canCharge, isAdmin } from 'helpers/authHelpers';
import notify from '../../lib/notify';
import { User } from '../../lib/store/user';
import QuestBoard from './QuestBoard';

type Props = { user: User; redirectMessage?: string; isMobile: boolean };

//const LOCAL_STORAGE_KEY = 'infoPanelOpen';

const styles = {
  grid: {
    padding: '5px',
  },
  card: { height: '100%', alignText: 'center' },
};

const TeacherDashboard = (props: Props) => {
  const { user, redirectMessage } = props;
  /*
  const [disabled, setDisabled] = React.useState(false);
  const [panelOpen, setPanelOpen] = React.useState(typeof window !== 'undefined'
    ? localStorage.getItem(LOCAL_STORAGE_KEY) == 'true'
    : !props.isMobile);
    */

  React.useEffect(() => {
    if (redirectMessage) {
      console.log(redirectMessage);
      notify(redirectMessage);
    }
  }, [redirectMessage]);

  return (
    <>
      <div style={styles.grid}>
        {canCharge(user) && (
          <CreateMeetingButton
            disabled={false}
            isAdmin={isAdmin(user.roles)}
            canCharge={canCharge(user)}
            user={user}
          />
        )}
      </div>

      <QuestBoard user={user} />
    </>
  );
}


export default TeacherDashboard;
