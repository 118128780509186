import { CardHeader, Grid } from '@material-ui/core';
import ClassCard from 'components/common/ClassCard';
import { getClassesToBrowse } from 'lib/api/meeting';
import { Meeting } from 'lib/store/meeting';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

type Props = {
  disabled: boolean;
  forCreator?: string;
  publicOnly?: boolean;
  includeFull?: boolean;
};

const BrowseClasses = (props: Props) => {
  const { disabled, publicOnly, forCreator, includeFull } = props;

  const router = useRouter();

  const [classes, setClasses] = useState<Meeting[]>([]);
  const [loading, setLoading] = useState(true);

  const rowClick = (row) => {
    const url = { pathname: 'meetings/[id]', query: { id: row._id } };
    router.push(url, `/meetings/${row._id}/`);
  };

  const getBrowsableClasses = async () => {
    const myClasses = await getClassesToBrowse(publicOnly, forCreator, includeFull);
    setClasses(myClasses);
    setLoading(false);
  };

  useEffect(() => {
    getBrowsableClasses();
  }, []);

  if (disabled) {
    return (
      <Grid container>
        <Grid item sm={12}>
          <CardHeader title="Browse classes" style={{ backgroundColor: '#e3e8ff' }} />
        </Grid>
        <Grid item sm={3} style={{ padding: '10px' }}></Grid>
        <Grid item sm={6} xs={12} style={{ padding: '10px' }}>
          <div style={{ textAlign: 'center' }}>coming soon</div>
        </Grid>
      </Grid>
    );
  }

  // https://i.pravatar.cc/150?img=${card.idx}
  // https://picsum.photos/40/?blur=1&random=${card.idx}
  return (
    <>
      {loading && <div>Fetching classes..</div>}
      <Grid container style={{ marginTop: '15px', marginBottom: '5px', zIndex: 1 }}>
        <Grid item sm={12} xs={12}>
          <CardHeader
            title="Browse Classes"
            style={{ backgroundColor: '#e3e8ff', marginBottom: '10px' }}
          />
        </Grid>

        {classes.map((card) => (
          <ClassCard card={card} rowClick={rowClick} key={card._id} attendees={card.attendees} />
        ))}
      </Grid>
    </>
  );
};

export default BrowseClasses;
