
import { Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import TeacherApplicationForm from 'components/teacher-application/TeacherApplicationForm';
import { getInvitesForMe } from 'lib/api/invite';
import { Store } from 'lib/store';
import { useEffect, useState } from 'react';

type Props = { store?: Store };

const InviteWrapper = (props: Props) => {
  const { store } = props;

  const [invites, setInvites] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const currentUser = store.currentUser;

  useEffect(() => {
    if(!currentUser.quests.hasApplication) {
      getInvitesForMe().then((invites) => {
        setInvites(invites);
      });  
    }
  }, [currentUser]);

  if(currentUser.quests.hasApplication) {
    return (<></>);
  }

  return (
    <>
      <div>
      <TeacherApplicationForm modalOpen={modalOpen} handleClose={() => {setModalOpen(false); 
          setTimeout(() => {
            window.location.reload();
          }, 700);
        }} successMessage={"Thank you for applying, we'll review this quickly and get back to you (usually within 24-48 hours)"}/>
        {invites && invites.map((entry, idx) => 
          (
            <Card key={idx}>
              <CardHeader title={'Teaching Invite'} />
              <CardContent>
                <div>from {`${entry.sentBy.firstName} ${entry.sentBy.lastName}`}</div>
                <div>message {entry.body}</div>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" style={{marginLeft:'auto'}} onClick={()=>setModalOpen(true)}>Apply to teach</Button>
              </CardActions>
            </Card>
          )
        )}
      </div>
    </>
  );
}


export default InviteWrapper;
