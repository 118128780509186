import { Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { User } from 'lib/store/user';
import { useState } from 'react';
import CreateMeetingModal from './CreateMeetingModal';

type Props = {
  disabled: boolean;
  onSuccess?: () => void;
  isAdmin: boolean;
  canCharge: boolean;
  user: User;
};

const CreateMeetingButton = (props: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { disabled, onSuccess, isAdmin, canCharge, user } = props;

  return (
    <>
      {modalOpen && (
        <CreateMeetingModal
          modalOpen={modalOpen}
          onSuccess={onSuccess}
          canCharge={canCharge}
          handleClose={() => setModalOpen(false)}
          isAdmin={isAdmin}
          user={user}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
        disabled={disabled && !isAdmin}
      >
        <AddCircleIcon />
        Create Class
      </Button>
    </>
  );
};

export default CreateMeetingButton;
