import sendRequestAndGetResponse from './sendRequestAndGetResponse';

const BASE_PATH = '/suggest';

export const suggestTopic = (data) =>
  sendRequestAndGetResponse(`${BASE_PATH}/topic/add`, {
    body: JSON.stringify(data),
    method: 'POST',
  });

export const getSuggestions = () => sendRequestAndGetResponse(`${BASE_PATH}/`, { method: 'GET' });

export const submitProposal = (suggestionId, data) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${suggestionId}/respond`, {
    body: JSON.stringify(data),
    method: 'POST',
  });
