import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import NProgress from 'nprogress';
import { CSSProperties, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { suggestTopic } from '../../lib/api/suggestion';
import notify from '../../lib/notify';

type Props = {
  modalOpen: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
};

type FormData = {
  topic: string;
  desc: string;
};

const validationSchema = yup.object().shape({
  topic: yup.string().required(),
  desc: yup.string().required(),
});

const styles: Record<string, CSSProperties> = {
  email: {
    display: 'flex',
    width: 'fit-content',
    padding: '8px 25px',
    backgroundColor: '#f0faff',
    marginRight: '15px',
    marginBottom: '15px',
  },
  error: {
    color: ' #cb4335 ',
  },
};

const SuggestTopicModal = (props: Props) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { modalOpen, handleClose, onSuccess } = props;
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      topic: '',
      desc: '',
    },
  });

  const createMeetingFn = handleSubmit(async (values) => {
    const { topic, desc } = values;
    if (disabled || !topic || !desc) {
      return;
    }
    NProgress.start();
    setDisabled(true);

    const data: Record<string, any> = {
      topic,
      desc,
    };

    try {
      await suggestTopic(data);
      notify('Topic Suggested');
      setDisabled(false);
      handleClose();
      onSuccess && onSuccess();
    } catch (error) {
      notify(error);
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  });

  return (
    <Dialog open={modalOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Suggest Topic</DialogTitle>
      <DialogContent>
        <form>
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              width: 'fit-content',
              minWidth: '400px',
            }}
          >
            <TextField
              autoFocus
              label={'Enter the topic here'}
              type={'text'}
              name={'topic'}
              fullWidth
              {...register('topic')}
            />
            {errors.topic && <div style={styles.error}>{errors.topic.message}</div>}
          </div>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              label={'Describe the topic in detail for our teachers to review'}
              type={'text'}
              name={'desc'}
              fullWidth
              minRows={6}
              multiline={true}
              {...register('desc')}
            />
            {errors.desc && <div style={styles.error}>{errors.desc.message}</div>}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
          Cancel
        </Button>
        <Button onClick={createMeetingFn} color={'primary'} disabled={disabled}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuggestTopicModal;
