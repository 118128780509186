import {
  Button,
  Card, CardContent, CardHeader, CardMedia, Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import { useState } from 'react';

type Props = { styles: object };

const StripeExplainer = function(props: Props) {
  const { styles } = props;
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Dialog open={modalOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Get paid with Stripe</DialogTitle>
        <DialogContent style={{ minWidth: '600px' }}>
          <p>Stripe is the service we use to let you accept online payments for your classes</p>
          <p>
            <a href="https://stripe.com/">https://stripe.com/</a>
          </p>
          <p>
            You create your account with them, then connect it with our service to get you paid
            quickly and securely
          </p>
          <Button onClick={() => setModalOpen(false)} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Card style={styles}>
        <CardMedia
          image="https://images.ctfassets.net/fzn2n1nzq965/3AGidihOJl4nH9D1vDjM84/9540155d584be52fc54c443b6efa4ae6/homepage.png?fm=jpg"
          style={{ paddingTop: '56.25%' }}
        />
        <CardHeader style={{ textAlign: 'center' }} title="Getting paid" />
        <CardContent style={{ display: 'flex' }}>
          <Button variant="outlined" style={{ margin: 'auto' }} onClick={() => setModalOpen(true)}>
            Learn more
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default StripeExplainer;
