import {
  Button,
  Card, CardContent, CardHeader, CardMedia, Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import { useState } from 'react';

type Props = { styles: object };

const BetterImages = function(props: Props) {
  const { styles } = props;
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Dialog open={modalOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Better Pictures</DialogTitle>
        <DialogContent style={{ minWidth: '600px' }}>
          <p>There are many places on the internet to find great pictures to enhance your posts</p>
          <p>
            One of our favorites is <a href="https://www.unsplash.com">www.unsplash.com</a>
          </p>
          <div>
            <img
              src="https://images.unsplash.com/photo-1506905925346-21bda4d32df4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&h=300&q=80"
              style={{ backgroundPosition: '0% 36%', width: '32%' }}
            />
            <img
              src="https://images.unsplash.com/photo-1613215428264-16f040dce665?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&h=300&q=80"
              style={{ backgroundPosition: '0% 36%', width: '32%' }}
            />
            <img
              src="https://images.unsplash.com/photo-1613865342914-5c41d188d02d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&h=300&q=80"
              style={{ backgroundPosition: '0% 36%', width: '32%' }}
            />
          </div>
          <p>Check it out find links to the images that help your classes stand out and enjoy!</p>
          <Button onClick={() => setModalOpen(false)} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Card style={styles}>
        <CardMedia
          image="https://images.unsplash.com/photo-1518708266825-ad9f4356d8ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
          style={{ paddingTop: '56.25%', backgroundPosition: '0% 36%' }}
        />
        <CardHeader
          style={{ textAlign: 'center' }}
          title="Make your content POP"
        />
        <CardContent style={{ display: 'flex' }}>
          <Button variant="outlined" style={{ margin: 'auto' }} onClick={() => setModalOpen(true)}>
            Learn more
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default BetterImages;
