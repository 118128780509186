import { Button } from '@material-ui/core';
import { Store } from 'lib/store';
import { Meeting } from 'lib/store/meeting';
import { filter } from 'lodash';
import moment from 'moment';
import Router from 'next/router';
import NProgress from 'nprogress';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { getMeetingListForAttendee } from '../../lib/api/meeting';
import notify from '../../lib/notify';
import { MEETING_STATE, PaymentType } from '../../utils/constants';
import JoinMeetingTableAction from './JoinMeetingTableAction';

type Props = {
  store: Store;
};

const AttendeeMeetingWrapper = (props: Props) => {
  const [data, setData] = useState<Meeting[]>([]);
  const { store } = props;

  const rowClicked = (row) => {
    Router.push(`/meetings/${row._id}/`);
  };

  const columns: TableColumn<any>[] = [
    {
      name: '',
      width: '220px',
      // eslint-disable-next-line react/display-name
      cell: (row) => {
        return (
          <Button
            variant="contained"
            fullWidth={true}
            color="primary"
            onClick={() => rowClicked(row)}
          >
            View Details
          </Button>
        );
      },
    },
    {
      name: '',
      // eslint-disable-next-line react/display-name
      cell: (row:Meeting) => {
        const classHasStarted = row.state == MEETING_STATE.STARTED;
        const joinBtnMsg = classHasStarted ? 'Join In Progress' : 'Class not started';

        //dont show disabled join class state - maybe say class not started?
        //maybe hide the button?

        const needsCard = row.paymentType == PaymentType.PaidParticipant && !store.currentUser.hasCardInformation;
        const disabled = !classHasStarted || needsCard;

        const disabledReason = !classHasStarted
          ? 'You can join the class once the teacher has started it'
          : 'You need to input your payment information';

        return disabled ? (
          disabledReason
        ) : (
          <JoinMeetingTableAction
            meeting={row}
            store={store}
            disabled={disabled}
            buttonMsg={joinBtnMsg}
          />
        );
      },
    },
    {
      name: 'Scheduled At',
      selector: (row) => row.scheduledAt,
      sortable: true,
      format: (row) => moment(row.scheduledAt).format('MM/DD/YYYY, hh:mm A'),
      width: '170px',
    },
    {
      name: 'Topic',
      selector: (row) => row.topic,
      sortable: true,
    },
  ];

  const fetchAttendeeMeetings = async () => {
    NProgress.start();
    try {
      const res = await getMeetingListForAttendee(store.currentUser._id);
      const attendeeMeetings:Meeting[] = filter(res.meetings, (m) => m.createdBy != store.currentUser._id);
      setData(attendeeMeetings);
    } catch (error) {
      notify(error);
    } finally {
      NProgress.done();
    }
  };

  useEffect(() => {
    fetchAttendeeMeetings();
  }, []);

  return (
    <>
      {data && data.length ? (
        <DataTable
          title="Classes to attend"
          keyField="_id"
          columns={columns}
          data={data}
          striped={true}
          responsive={true}
          onRowClicked={rowClicked}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default AttendeeMeetingWrapper;
