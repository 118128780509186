import {
  Button,
  Card, CardContent, CardHeader, CardMedia, Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import { useState } from 'react';

type Props = { styles: object };

const AffiliateLinks = function(props: Props) {
  const { styles } = props;
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Dialog open={modalOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Earn more from your classes</DialogTitle>
        <DialogContent style={{ minWidth: '600px' }}>
          <p>
            Did you know that by setting up an affiliate account, you can earn money when people buy
            supplies for your class by following the links you post?
          </p>
          <p>
            <a href="https://affiliate-program.amazon.com/">
              https://affiliate-program.amazon.com/
            </a>
          </p>
          <p>
            Follow the instructions to get set up, then create specialized links before you post
            them to your class!
          </p>
          <Button onClick={() => setModalOpen(false)} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Card style={styles}>
        <CardMedia
          image="https://is5-ssl.mzstatic.com/image/thumb/Purple124/v4/ba/f9/d5/baf9d514-ef89-2138-3168-389179a5bbdf/AppIcon-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x630wa.png"
          style={{ paddingTop: '56.25%' }}
        />
        <CardHeader
          style={{ textAlign: 'center' }}
          title="Affiliate links for your class"
        />
        <CardContent style={{ display: 'flex' }}>
          <Button variant="outlined" style={{ margin: 'auto' }} onClick={() => setModalOpen(true)}>
            Learn more
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default AffiliateLinks;
