import { inject, observer } from 'mobx-react';
import Head from 'next/head';
import * as React from 'react';

import Layout from '../components/layout';

import notify from '../lib/notify';
import { Store } from '../lib/store';
import withAuth from '../lib/withAuth';
import AttendeeDashboard from '../components/dashboard/AttendeeDashboard';
import { Typography } from '@material-ui/core';

type Props = { isMobile: boolean; store: Store; redirectMessage?: string };

type State = { newName: string; disabled: boolean };

class YourDashboard extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      newName: this.props.store.currentUser.displayName,
      disabled: false,
    };
  }

  public render() {
    const name = `${process.env.NAME}`;
    return (
      <Layout {...this.props}>
        <Head>
          <title>{name} : Welcome</title>
        </Head>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: this.props.isMobile ? '0px' : '20px 30px',
            fontSize: '15px',
            margin: 'auto',
            maxWidth: '1024px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '25px',
            }}
          >
            <Typography variant={'h2'} style={{ marginRight: '5px', marginLeft: '5px' }}>
              {name}
            </Typography>
          </div>
          <div>
            {/* this enclosing tag is to fix the issue with stretching out the attendee dashboard to fill the screen */}
            <AttendeeDashboard store={this.props.store} />
          </div>
        </div>
      </Layout>
    );
  }

  public componentDidMount() {
    const { redirectMessage } = this.props;

    if (redirectMessage) {
      console.log(redirectMessage);
      notify(redirectMessage);
    }
  }
}

export default withAuth(inject('store')(observer(YourDashboard)));
