import { observer } from 'mobx-react';
import Router from 'next/router';
import React from 'react';

import * as NProgress from 'nprogress';

import { Store, getStore } from './store';
import { isInRole } from 'helpers/authHelpers';

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', (url) => {
  const store = getStore();
  if (store) {
    store.changeCurrentUrl(url);
  }

  if (window && process.env.GA_MEASUREMENT_ID) {
    (window as any).gtag('config', process.env.GA_MEASUREMENT_ID, {
      page_path: url,
    });
  }

  NProgress.done();
});

Router.events.on('routeChangeError', () => NProgress.done());

export default function withAuth(
  Component,
  { loginRequired = true, logoutRequired = false, requiredRole = null } = {},
) {
  class WithAuth extends React.Component<{ store: Store }> {
    public static async getInitialProps(ctx) {
      const { req } = ctx;

      let pageComponentProps = {};

      if (Component.getInitialProps) {
        pageComponentProps = await Component.getInitialProps(ctx);
      }

      return {
        ...pageComponentProps,
        isServer: !!req,
      };
    }

    public componentDidMount() {
      const { store } = this.props;
      const user = store.currentUser;

      if (loginRequired && !logoutRequired && !user) {
        console.log(`${loginRequired} ${user} login required and no user found`);
        Router.push('/login');
        return;
      }

      if (requiredRole && !isInRole(user.roles, requiredRole)) {
        Router.push('/dashboard');
        return;
      }

      let redirectUrl = '/login';
      let asUrl = '/login';
      if (user) {
        redirectUrl = '/dashboard';
        asUrl = '/dashboard';
      }

      if (logoutRequired && user) {
        console.log(`${loginRequired} ${user} logout required and no user found`);
        Router.push(redirectUrl, asUrl);
      }
    }

    public render() {
      const { store } = this.props;
      const user = store.currentUser;

      if (loginRequired && !logoutRequired && !user) {
        return null;
      }

      if (logoutRequired && user) {
        return null;
      }

      return <Component {...this.props} />;
    }
  }

  return observer(WithAuth);
}
