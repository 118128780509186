import { Button, CardHeader, Grid, Typography } from '@material-ui/core';
import AttendeeMeetingWrapper from 'components/meeting/AttendeeMeetingWrapper';
import CreatorMeetingWrapper from 'components/meeting/CreatorMeetingWrapper';
import AttendeeCardInformation from 'components/payment/AttendeeCardInformation';
import { isCreator } from 'helpers/authHelpers';
import { Store } from 'lib/store';
import { useState } from 'react';
import SuggestTopicButton from '../attendee/SuggestTopicButton';
import BrowseClasses from './BrowseClasses';
import EnterNameCard from './EnterNameCard';
import InviteWrapper from './InviteWrapper';
import RecommendedTeachers from './RecommendedTeachers';
import TeacherDashboard from './TeacherDashboard';

type Props = { store: Store; redirectMessage?: string };

const AttendeeDashboard = (props: Props) => {
  const { store } = props;

  const user = store.currentUser;
  const isTeacher = isCreator(store.currentUser.roles);
  const [showCardEntryForm, setShowCardEntryForm] = useState(false);
  return (
    <>
      {isTeacher && <TeacherDashboard user={user} isMobile={store.isMobile} />}
      {isTeacher && <CreatorMeetingWrapper store={store} />}
      {!isTeacher && <InviteWrapper store={store} />}
      <AttendeeMeetingWrapper store={store} />
      {(/*!user.hasCardInformation ||*/
        !store.currentUser.firstName ||
        !store.currentUser.lastName) && (
        <Grid container style={{ marginTop: '25px' }}>
          <Grid item sm={12} xs={12}>
            <CardHeader
              title="Finish setting up your account"
              style={{ backgroundColor: '#e3e8ff', marginBottom: '15px' }}
            />
          </Grid>
        </Grid>
      )}
      {(!store.currentUser.firstName || !store.currentUser.lastName) && (
        <Grid container style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Grid item sm={3}></Grid>
          <Grid item sm={6}>
            <EnterNameCard user={user} title={true} />
          </Grid>
        </Grid>
      )}

      {false && !user.hasCardInformation && (
        <Grid container style={{ marginTop: '10px' }}>
          <Grid item sm={3} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">
              You will need a credit card added to your account to join any premium classes. You do
              not need to enter one to access free classes.
            </Typography>
          </Grid>
          <Grid item sm={3} xs={12}></Grid>
          <Grid item sm={3} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            {showCardEntryForm ? (
              <AttendeeCardInformation
                userCard={user.hasCardInformation && user.stripeCard.card}
                userEmail={user.email}
                userHasCardInfo={user.hasCardInformation}
                open={true}
                store={store}
              />
            ) : (
              <Button variant="outlined" onClick={() => setShowCardEntryForm(true)}>
                Add card information
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {false && (<BrowseClasses disabled={false} publicOnly={true} />)}
      <RecommendedTeachers disabled={false} />
      {store.currentUser.firstName && store.currentUser.lastName && (
        <Grid container style={{ marginTop: '10px' }}>
          <Grid item sm={3}></Grid>
          <Grid item sm={6}>
            <SuggestTopicButton />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AttendeeDashboard;
