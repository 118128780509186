import {
  Button,
  Card, CardContent, CardHeader, CardMedia, Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import { useState } from 'react';

type Props = { styles: object };

const ZoomExplainer = function(props: Props) {
  const { styles } = props;
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Dialog open={modalOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Zoom connects groups over video</DialogTitle>
        <DialogContent style={{ minWidth: '600px' }}>
          <p>Zoom is the service that hosts your video calls</p>
          <p>
            <a href="https://www.zoom.com/">https://www.zoom.com/</a>
          </p>
          <p>
            Zoom lets you host your classes online and handles nearly everything you need while your
            class is in session
          </p>
          <Button onClick={() => setModalOpen(false)} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Card style={styles}>
        <CardMedia
          image="https://i.pcmag.com/imagery/reviews/05fRE6utWAtXmByTrwqgdcU-9..1569481702.jpg"
          style={{ paddingTop: '56.25%' }}
        />
        <CardHeader style={{ textAlign: 'center' }} title="Basics" />
        <CardContent style={{ display: 'flex' }}>
          <Button variant="outlined" style={{ margin: 'auto' }} onClick={() => setModalOpen(true)}>
            Learn more
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default ZoomExplainer;
