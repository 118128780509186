import React, { CSSProperties, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { getStripeConnectURL } from '../lib/api/payments';
import NProgress from 'nprogress';
import notify from '../lib/notify';
import { Button, Grid } from '@material-ui/core';

type Props = {
  stripeConnected: boolean;
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: '15px',
  },
  message: {
    marginRight: '25px',
  },
};

const StripeConnected = (props: Props) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { stripeConnected } = props;

  const stripeConnect = async () => {
    if (disabled) {
      return;
    }
    NProgress.start();
    setDisabled(true);

    try {
      const response = await getStripeConnectURL();
      window.location.href = response.url;

      notify('Going to Stripe for authorization');
    } catch (error) {
      notify(error);
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  return (
    <div style={styles.container}>
      {!stripeConnected ? (
        <Grid container>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={stripeConnect}
              disabled={disabled}
              fullWidth={true}
            >
              Setup Stripe
            </Button>
          </Grid>
          <Grid item xs={7} style={{ margin: 'auto' }}>
            Configuring your stripe account lets you charge for classes
          </Grid>
        </Grid>
      ) : (
        <Alert style={styles.message} severity={'success'}>
          Stripe connected.
        </Alert>
      )}
    </div>
  );
};

export default StripeConnected;
