import { Button, Card, CardContent } from '@material-ui/core';
import { useState } from 'react';
import SuggestTopicModal from './SuggestTopicModal';

type Props = {
  onSuccess?: () => void;
};

const SuggestTopicButton = (props: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { onSuccess } = props;

  return (
    <Card>
      {modalOpen && (
        <SuggestTopicModal
          modalOpen={modalOpen}
          onSuccess={onSuccess}
          handleClose={() => setModalOpen(false)}
        />
      )}
      <CardContent>
        <div style={{ textAlign: 'center' }}>
          Don't see the class you're looking for? Why not suggest it?
        </div>
        <Button
          style={{ marginTop: '15px' }}
          variant="outlined"
          color="primary"
          onClick={() => setModalOpen(true)}
          fullWidth={true}
        >
          Suggest Topic
        </Button>
      </CardContent>
    </Card>
  );
};

export default SuggestTopicButton;
