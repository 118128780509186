import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import NProgress from 'nprogress';
import { CSSProperties, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { inviteCreator } from '../../lib/api/invites';
import notify from '../../lib/notify';

type Props = {
  modalOpen: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
};

type FormData = {
  name: string;
  email: string;
  body: string;
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  body: yup.string().required(),
});

const styles: Record<string, CSSProperties> = {
  email: {
    display: 'flex',
    width: 'fit-content',
    padding: '8px 25px',
    backgroundColor: '#f0faff',
    marginRight: '15px',
    marginBottom: '15px',
  },
  removeEmail: {
    marginLeft: '20px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  error: {
    color: ' #cb4335 ',
  },
};

const InviteCreatorModal = (props: Props) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [emptyEmailsError, setEmptyEmailsError] = useState<boolean>(false);
  const { modalOpen, handleClose, onSuccess } = props;
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      body: '',
    },
  });

  const inviteCreatorFn = handleSubmit(async (values) => {
    console.log(values);
    const { name, email, body } = values;
    if (disabled || !email) {
      setEmptyEmailsError(true);
      return;
    }
    NProgress.start();
    setDisabled(true);

    const data: Record<string, any> = {
      name,
      email,
      body,
    };

    console.log(data);

    try {
      const result = await inviteCreator(data);
      if (result && result.error) {
        throw new Error(result.error);
      }
      notify('Invite Sent!');
      setDisabled(false);
      handleClose();
      onSuccess && onSuccess();
    } catch (error) {
      notify(error);
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  });

  return (
    <Dialog open={modalOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Send an invite</DialogTitle>
      <DialogContent>
        <form>
          <div>
            <TextField
              label={'Email Address'}
              type={'email'}
              name={'email'}
              fullWidth
              {...register('email')}
            />
            {errors.email && <div style={styles.error}>{errors.email.message}</div>}
            {emptyEmailsError && <div style={styles.error}>Please add at least on email.</div>}
            <Typography variant={'caption'}>Enter email of the teacher to invite</Typography>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              autoFocus
              label={'Teacher Name'}
              type={'text'}
              name={'name'}
              fullWidth
              {...register('name')}
            />
            {errors.name && <div style={styles.error}>{errors.name.message}</div>}
          </div>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              label={'Customize the message'}
              type={'text'}
              name={'body'}
              fullWidth
              multiline={true}
              minRows={5}
              {...register('body')}
            />
            {errors.body && <div style={styles.error}>{errors.body.message}</div>}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
          Cancel
        </Button>
        <Button onClick={inviteCreatorFn} color={'primary'} disabled={disabled}>
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteCreatorModal;
