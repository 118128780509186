import { Button, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import CreateMeetingButton from 'components/meeting/CreateMeetingButton';
import StripeConnected from 'components/StripeConnected';
import ZoomConnected from 'components/ZoomConnected';
import { canCharge, isAdmin } from 'helpers/authHelpers';
import { User } from 'lib/store/user';
import Router from 'next/router';

type Props = { user: User; };

//create a react component
const QuestBoard = (props: Props) => {
  const { user } = props;

  //help teachers make sure they have an umbrella policy<br />
  //help teachers set up a class template<br />
  //help teachers set up their public profile (helps us with SEO) <br />
  //is setup with at least 1 school

  let stepsRemaining = 4;
  if (user.hasStripeConnected) {
    stepsRemaining--;
  }

  if (user.hasZoomConnected) {
    stepsRemaining--;
  }

  const quests: any = user.quests;
  if (quests.hasClassTemplate) {
    stepsRemaining--;
  }

  if (user.avatarUrl) {
    stepsRemaining--;
  }

  /*
  if (user.hasUmbrellaPolicy) {
    stepsRemaining--;
  }

  if (user.isQualifiedCount > 0) {
    stepsRemaining--;
  }
  */

  return stepsRemaining > 0 ?
    (
      <Grid container>
        <Grid item sm={12} xs={12} >
          <Card >
            <CardHeader
              title={`${stepsRemaining} step${stepsRemaining > 1 ? 's' : ''
                } left to complete your setup`}
            ></CardHeader>
            <CardContent>
              {!user.hasZoomConnected && (
                <ZoomConnected zoomConnected={user.hasZoomConnected} />
              )}
              {!user.hasStripeConnected && (
                <StripeConnected stripeConnected={user.hasStripeConnected} />
              )}
              {!quests.hasClassTemplate && (
                <Grid container style={{marginBottom: '15px'}}>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        Router.push(
                          `/profile/${user._id}/templates`,
                          `/profile/${user._id}/templates`,
                        )
                      }}
                      disabled={false}
                      fullWidth={true}
                    >
                      Create your first class
                    </Button>
                  </Grid>
                  <Grid item xs={7} style={{ margin: 'auto' }}>
                    Create your first class to get started
                  </Grid>
                </Grid>
              )}
              {!user.avatarUrl && 
              (<Grid container style={{marginBottom: '15px'}}>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        Router.push(
                          `/profile/${user._id}`,
                          `/profile/${user._id}`,
                        )
                      }}
                      disabled={false}
                      fullWidth={true}
                    >
                      Set up your profile
                    </Button>
                  </Grid>
                  <Grid item xs={7} style={{ margin: 'auto' }}>
                    Add a profile picture to complete this step
                  </Grid>
                </Grid>)}
            </CardContent>
          </Card>
        </Grid>
        {user.hasZoomConnected && (
          <Grid item sm={12} xs={12} style={{marginTop:'15px'}}>
            <Card >
              <CardHeader title="How about hosting a free meeting?" />
              <CardContent>
                You wont be able to charge for the class and you wont be able to get a recording
                of the class but you can try us out!
                <br />
                <br />
                <CreateMeetingButton
                  disabled={false}
                  canCharge={canCharge(user)}
                  isAdmin={isAdmin(user.roles)}
                  user={user}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    ) : (<></>);
}

export default QuestBoard;