/* eslint-disable react/display-name */
import { Box, Button, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import TeacherHints from 'components/dashboard/TeacherHints';
import { Store } from 'lib/store';
import { Meeting } from 'lib/store/meeting';
import _, { map } from 'lodash';
import moment from 'moment';
import Router from 'next/router';
import NProgress from 'nprogress';
import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { getMeetingListForCreator } from '../../lib/api/meeting';
import notify from '../../lib/notify';
import { MEETING_STATE, PaymentType } from '../../utils/constants';
import JoinMeetingTableAction from './JoinMeetingTableAction';

type Props = {
  store: Store;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HINTS_TAB_IDX = 2;
const UPCOMING_TAB_IDX = 0;

const CreatorMeetingWrapper = (props: Props) => {
  const [upcomingMeetings, setUpcomingMeetings] = useState<Meeting[]>([]);
  const [pastMeetings, setPastMeetings] = useState<Meeting[]>([]);
  const [tabState, setTabState] = useState(HINTS_TAB_IDX);
  const [isLoading, setIsLoading] = useState(true);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabState(newValue);
  };

  const { store } = props;

  const getBtnMsg = (state) =>
    state === MEETING_STATE.FINISHED
      ? 'class ended'
      : state == MEETING_STATE.STARTED
      ? 'Join in progress'
      : 'Start class';

  const columns: TableColumn<any>[] = [
    {
      name: '',
      width: '220px',
      // eslint-disable-next-line react/display-name
      cell: (row) => {
        const joinBtnMsg = getBtnMsg(row.state);
        const disabled = row.state === MEETING_STATE.FINISHED;

        return (
          <JoinMeetingTableAction
            store={store}
            meeting={row}
            disabled={disabled}
            buttonMsg={joinBtnMsg}
          />
        );
      },
    },
    {
      name: 'Scheduled At',
      selector: (row) => row.scheduledAt,
      sortable: true,
      format: (row) => moment(row.scheduledAt).format('MM/DD/YYYY, hh:mm A'),
      width: '170px',
    },
    {
      name: 'Seats',
      selector: (row) => row.members,
      format: (row:Meeting) => {
        if (row.paymentType == PaymentType.Free) {
          return `${row.members.length}/${row.classSize}`;
        } else {
          const paid = _.filter(row.members, (m) => m.hasAuthorizedPayment).length;
          return `${paid}/${row.members.length}`;
        }
      },
      width: '70px',
    },
    {
      name: 'Type',
      selector: (row) => row.paymentType,
      format: (row:Meeting) => {
        switch (row.paymentType) {
          case PaymentType.Free:
            return 'Free';
          case PaymentType.Invoice:
            return 'Invoice';
          case PaymentType.PaidParticipant:
            return 'Per seat';
        }
      },
      width: '100px',
    },
    {
      name: 'Topic',
      selector: (row) => row.topic,
      sortable: true,
    },
    {
      name: ' ',
      selector: (row) => row._id,
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      format: (_row) => (
        <>
          <Tooltip title={'this feature is not yet available'}>
            <Button color={'primary'} variant={'outlined'} disabled={false}>
              <EmailIcon />
            </Button>
          </Tooltip>
        </>
      ),
      width: '170px',
    },
  ];

  const rowClicked = (row) => {
    Router.push(`/meetings/${row._id}/`);
  };

  const fetchCreatorMeetings = async () => {
    NProgress.start();
    setIsLoading(true);
    try {
      const upcomingMeetings = await getMeetingListForCreator(store.currentUser._id, false);
      setUpcomingMeetings(map(upcomingMeetings.meetings, (m) => new Meeting(m)));

      const pastMeetings = await getMeetingListForCreator(store.currentUser._id, true);
      setPastMeetings(map(pastMeetings.meetings, (m) => new Meeting(m)));

      if (upcomingMeetings.meetings.length) {
        setTabState(UPCOMING_TAB_IDX);
      }
    } catch (error) {
      notify(error);
    } finally {
      setIsLoading(false);
      NProgress.done();
    }
  };

  useEffect(() => {
    fetchCreatorMeetings();
  }, []);

  return (
    <>
      <Tabs value={tabState} onChange={handleTabChange} aria-label="simple tabs example">
      <Tab label="Upcoming" value={0} {...a11yProps(0)} disabled={!upcomingMeetings || !upcomingMeetings.length}/>
      <Tab label="Past" value={1} {...a11yProps(1)} disabled={!pastMeetings || !pastMeetings.length} />
      <Tab label="Hints" value={2} {...a11yProps(2)} />
      </Tabs>
      <hr />
      {isLoading ? (<>
      <div>loading...</div>
      </>) : (<>
      <TabPanel value={tabState} index={0}>
        {upcomingMeetings && upcomingMeetings.length ? (
          <DataTable
            title="Upcoming Classes"
            keyField="_id"
            columns={columns}
            data={upcomingMeetings}
            striped={true}
            responsive={true}
            onRowClicked={rowClicked}
          />
        ) : (
          <Typography variant={'subtitle1'}>
            <p>no upcoming classes. Why not find a class below?</p>
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={tabState} index={1}>
        {pastMeetings && pastMeetings.length ? (
          <DataTable
            title="Past Classes"
            keyField="_id"
            columns={columns}
            data={pastMeetings}
            striped={true}
            responsive={true}
            onRowClicked={rowClicked}
          />
        ) : (
          <p>no previous classes</p>
        )}
      </TabPanel>
      <TabPanel value={tabState} index={2}>
        <TeacherHints user={store.currentUser} />
      </TabPanel>
      </>
      )}
    </>
  );
};

export default CreatorMeetingWrapper;
