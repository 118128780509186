import { Button, Card, CardContent, CardHeader, CardMedia, Grid } from '@material-ui/core';
import InviteCreatorButton from 'components/creator/InviteCreatorButton';
import { User } from 'lib/store/user';
import Router from 'next/router';
import AffiliateLinks from './AffiliateLinks';
import BetterImages from './BetterImagesButton';
import StripeExplainer from './StripeExplainer';
import ZoomExplainer from './ZoomExplainer';

const styles = {
  grid: {
    padding: '3px',
  },
  card: { width: '100%', height: '100%', alignText: 'center' },
};

type Props = { user: User };

const TeacherHints = (props: Props) => {
  const { user } = props;
  return (
    <Grid container spacing={1}>
      <Grid item md={3} style={styles.grid} xs={12}>
        <ZoomExplainer styles={styles.card} />
      </Grid>
      <Grid item md={3} style={styles.grid} xs={12}>
        <StripeExplainer styles={styles.card} />
      </Grid>
      <Grid item md={3} style={styles.grid} xs={12}>
        <Card style={styles.card}>
          <CardMedia
            image="https://images.unsplash.com/photo-1544535830-9df3f56fff6a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
            style={{ paddingTop: '56.25%' }}
          />
          <CardHeader style={{ textAlign: 'center' }} title="Got a question?" />
          <CardContent style={{ display: 'flex' }}>
          <Button variant="outlined" style={{ margin: 'auto' }} onClick={() =>
                    Router.push(
                      process.env.DISCORD_INVITE_URL,
                      process.env.DISCORD_INVITE_URL,
                    )
                  }>
            Ask in discord
          </Button>

          </CardContent>
        </Card>
      </Grid>
      <Grid item md={3} style={styles.grid} xs={12}>
        <InviteCreatorButton disabled={false} />
      </Grid>
      {(!user.avatarUrl || !user.quote || !user.firstName || !user.lastName) && (
        <Grid item md={3} style={styles.grid} xs={12}>
          <Card style={styles.card}>
            <CardMedia
              image="https://images.unsplash.com/photo-1532799749305-ff96b2b642e9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1301&q=80"
              style={{ paddingTop: '56.25%' }}
            />
            <CardHeader style={{ textAlign: 'center' }} title="Want to be featured?" />
            <CardContent>
              <p style={{ textAlign: 'center', width: '100%' }}>
                Make sure you've entered your name, uploaded a photo and set your quote
              </p>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item md={3} style={styles.grid} xs={12}>
        <AffiliateLinks styles={styles.card} />
      </Grid>
      <Grid item md={3} style={styles.grid} xs={12}>
        <BetterImages styles={styles.card} />
      </Grid>
      <Grid item md={3} style={styles.grid} xs={12}>
        <Card style={styles.card}>
          <CardMedia
            image="https://images.unsplash.com/photo-1582091652153-eb8f55ff7cd9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80"
            style={{ paddingTop: '56.25%', backgroundPosition: '0% 36%' }}
          />
          <CardHeader style={{ textAlign: 'center' }} title="Make great flyers" />
          <CardContent>
            <p style={{ textAlign: 'center', width: '100%' }}>
              Using templates from <a href="https://www.canva.com/">Canva</a> or <a href="https://www.pixelmator.com/">Pixelmator</a>
            </p>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TeacherHints;
