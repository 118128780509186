import { Card, CardContent, CardHeader, CardMedia, Grid } from '@material-ui/core';
import { getTeacherRecommendations } from 'lib/api/recommendation';
import Router from 'next/router';
import { useEffect, useState } from 'react';

type Props = { disabled: boolean };

const RecommendedTeachers = (props: Props) => {
  const { disabled } = props;

  const [recommendations, setRecommendations] = useState<Record<string, any>>([]);

  if (disabled) {
    return (
      <Grid container>
        <Grid item sm={12}>
          <CardHeader title="Recommended Teachers" style={{ backgroundColor: '#ededed' }} />
        </Grid>
        <Grid item sm={3} style={{ padding: '10px' }}></Grid>
        <Grid item sm={6} xs={12} style={{ padding: '10px' }}>
          <div style={{ textAlign: 'center' }}>coming soon</div>
        </Grid>
      </Grid>
    );
  }

  const fetchRecommendations = async () => {
    const recos = await getTeacherRecommendations();
    setRecommendations(recos);
  };

  useEffect(() => {
    fetchRecommendations();
  }, []);

  return (
    <>
      {recommendations && recommendations.length > 0 && (
        <Grid container style={{ marginTop: '5px', marginBottom: '5px' }}>
          <Grid item sm={12}>
            <CardHeader
              title="Recommended Teachers"
              style={{ backgroundColor: '#e3e8ff', marginBottom: '10px' }}
            />
          </Grid>

          {recommendations.map((red) => (
            <Grid item sm={4} xs={12} key={red._id} style={{ padding: '10px' }}>
              <Card style={{ height: '100%' }} onClick={() => Router.push(`/profile/${red._id}`)}>
                <CardMedia image={red.avatarUrl} style={{ paddingTop: '56.25%' }} />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
                >
                  <CardHeader
                    title={red.fullName}
                    style={{
                     width: '100%',
                    }}
                  />
                </div>
                {red.quote && (
                <CardContent>{red.quote}</CardContent>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default RecommendedTeachers;
